import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useNavigate } from 'react-router-dom';

import App from './App';
import authClient from './auth';
import './index.css';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppWithAuth />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

function AppWithAuth() {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
      replace: true,
    });
  };
  return (
    <Security oktaAuth={authClient} restoreOriginalUri={restoreOriginalUri}>
      <App />
    </Security>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
