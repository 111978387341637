import axios from 'axios';

import authClient from '../auth';

// import { API_URL } from '../constants';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Kinship-Source': 'THE_WILDEST_ADMIN',
  },
  mode: 'no-cors',
  crossdomain: true,
  // baseURL: API_URL,
  baseURL: process.env.REACT_APP_KINTOPIA_API_BASE_PATH,
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = await authClient.getAccessToken();
  config.headers.common['Authorization'] = `Bearer ${token}`;
  return config;
});

// consider renaming to authedAxiosInstace or something later on.
export default axiosInstance;
