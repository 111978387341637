import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Autocomplete, Pagination, TextField, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { debounce } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { AppContext } from '../../App';
import { UserProfile } from '../../types';
import axiosInstance from '../../utils/axiosInstance';

export function UserProfileList() {
  const [searchInput, setSearchInput] = useState('');
  const pageSize = 25;
  const appContext = useContext(AppContext);

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    appContext.setPage(value - 1);
  };

  /* eslint-disable */
  const debounceFetch = useMemo(
    () =>
      debounce(async (input, page, abortController) => {
        try {
          // handle resetting page index when we have new search input
          let pageIndex = page;
          if (searchInput !== input) {
            appContext.setPage(0);
            pageIndex = 0;
          }

          const userProfiles = await axiosInstance.get('/admin/user-profile/', {
            signal: abortController.signal,
            params: {
              pageSize: pageSize,
              page: pageIndex,
              email: input,
            },
          });

          appContext.setUserProfiles(userProfiles.data.profiles);
          appContext.setNumberOfResults(userProfiles.data.count);
        } catch (e) {
          console.log('aborted');
        }
      }, 800),
    [appContext.page]
  );
  /* eslint-enable */

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchSearch() {
      await debounceFetch(searchInput, appContext.page, abortController);
    }

    fetchSearch();

    return () => abortController.abort();
  }, [searchInput, appContext.page, debounceFetch]);

  const handleClick = (event: React.MouseEvent<unknown>, userId: string) => {
    // navigate to route component PetProfile of the petId
    navigate(`/user-profile/${userId}`);
  };

  return (
    <>
      <Typography variant="h2" component="h2">
        {`User Search`}
      </Typography>

      <Autocomplete
        id="user-search"
        freeSolo
        options={appContext.userProfiles.map(
          (userProfile) => userProfile.email
        )}
        onInputChange={(event, newInputValue) => {
          setSearchInput(newInputValue);
        }}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField {...params} name="userSearch" label="Search by Email" />
        )}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">ID</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appContext.userProfiles.map((user: UserProfile) => (
              <TableRow
                hover
                onClick={(event) => handleClick(event, user.id)}
                role="button"
                tabIndex={-1}
                key={user.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{user.email}</TableCell>
                <TableCell align="left">{user.id}</TableCell>
                <TableCell align="right">
                  <OpenInNewIcon />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography>Page: {appContext.page + 1}</Typography>
      <Pagination
        count={Math.ceil(appContext.numberOfResults / pageSize)}
        page={appContext.page + 1}
        // @ts-ignore
        onChange={handleChange}
      />
    </>
  );
}
